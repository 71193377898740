// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("./../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contests-js": () => import("./../src/pages/contests.js" /* webpackChunkName: "component---src-pages-contests-js" */),
  "component---src-pages-eaters-js": () => import("./../src/pages/eaters.js" /* webpackChunkName: "component---src-pages-eaters-js" */),
  "component---src-pages-host-js": () => import("./../src/pages/host.js" /* webpackChunkName: "component---src-pages-host-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-records-js": () => import("./../src/pages/records.js" /* webpackChunkName: "component---src-pages-records-js" */),
  "component---src-pages-safety-js": () => import("./../src/pages/safety.js" /* webpackChunkName: "component---src-pages-safety-js" */),
  "component---src-pages-shop-js": () => import("./../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-article-list-js": () => import("./../src/templates/article-list.js" /* webpackChunkName: "component---src-templates-article-list-js" */),
  "component---src-templates-contest-js": () => import("./../src/templates/contest.js" /* webpackChunkName: "component---src-templates-contest-js" */),
  "component---src-templates-eater-js": () => import("./../src/templates/eater.js" /* webpackChunkName: "component---src-templates-eater-js" */)
}

